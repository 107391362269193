<template>
  <div>
    <div class="text-center" style="height: 90vh">
      SSO 登入
    </div>
  </div>
</template>

<script>
import { loginBySSO2 } from '@/api/user'
import { siteLogin } from '@/utils/user'
import { showNotice, getStoreCode } from '@/utils/tool'

export default {
  name: 'SSOReturn',
  data() {
    return {}
  },
  created() {
    this.$store.commit('SET_LOADING', true)
    const self = this
    if (
      self.$route.query.uid &&
      self.$route.query.verify &&
      getStoreCode() === 'everrich'
    ) {
      const body = {
        type: getStoreCode(),
        uid: self.$route.query.uid,
        verify: self.$route.query.verify
      }
      loginBySSO2(body).then(response => {
        this.$store.commit('SET_MEMBER_INFO', response.data.data.info)
        self.$store.commit('SET_LOGIN_TYPE', 'everrich')
        let topath = '/index'
        if (self.$route.query.to) {
          if (decodeURI(self.$route.query.to).indexOf('http') > -1) {
            const path = decodeURI(self.$route.query.to).split('/')[3]
            topath =
              '/' +
              decodeURI(self.$route.query.to).substring(
                decodeURI(self.$route.query.to).indexOf(path)
              )
          }
        }
        siteLogin(response, topath) // 之後調整路徑
      })
    } else {
      showNotice('everrich登入失敗(token) 請重新嘗試', function() {
        self.$router.push({ path: '/index' }).catch(() => {})
      })
    }
  },
  destroyed() {
    this.$store.commit('SET_LOADING', false)
  }
}
</script>
